import HydreightLogo from '../../../../app/assets/images/hydreight.png';
import DripbarLogo from '../../../../app/assets/images/dripbar.png';
import TelemedicineLogo from '../../../../app/assets/images/telemedicine.png';
import MetaVetLogo from '../../../../app/assets/images/metavet.png';
import MiltestesLogo from '../../../../app/assets/images/miltestes.png';
import VSDigitalLogo from '../../../../app/assets/images/vsdigital.png';
import React from 'react';
import { useState } from 'react';
import { Image } from "react-bootstrap";
import { DataConstant } from '../../../constants';
import SearchIcon from '@material-ui/icons/Search';
const { REACT_APP_WHITELABELNAME } = process.env;

export const Header = ({ history, type, redirectionCall, height,
    displayList, setFilteredData,
    displayListTLP, setFilteredDataTLP
}) => {
    const [searchValue, setSearchValue] = useState([]);
    const [showSearch, setShowSearch] = useState(false);

    let logo = "";
    let logoClass = "";

    switch (REACT_APP_WHITELABELNAME?.toLowerCase()) {

        case DataConstant.whiteLabelNames.telemedicine.name.toLowerCase():
            logo = TelemedicineLogo;
            logoClass = "";
            break;
        case DataConstant.whiteLabelNames.dripbar.name.toLowerCase():
            logo = DripbarLogo;
            logoClass = "";
            break;
        case DataConstant.whiteLabelNames.hydreight.name.toLowerCase():
            logo = HydreightLogo;
            logoClass = "w-100px";
            break;
        case DataConstant.whiteLabelNames.metaVet.name.toLowerCase():
            logo = MetaVetLogo;
            logoClass = "w-100px";
            break;
        case DataConstant.whiteLabelNames.milTeste.name.toLowerCase():
            logo = MiltestesLogo;
            logoClass = "w-100px";
            break;
        case DataConstant.whiteLabelNames.vsdigital.name.toLowerCase():
            logo = VSDigitalLogo;
            logoClass = "w-100px";
            break;
        default:
            break;
    }

    const exitResponse = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ isExitWebview: true }));
    }

    const handleSearchChange = (value) => {
        setSearchValue(value);
        const searchData = value.toLowerCase();
        let filteredList = [];
        if (displayList) {
            filteredList = displayList.data.filter((item) => {
                return (
                    item.name.toLowerCase().includes(searchData) ||
                    item.description.toLowerCase().includes(searchData)
                );
            });
            setFilteredData(filteredList);
        }
        else if (displayListTLP) {
            const filteredList = displayListTLP.data.filter((item) => {
                return (
                    item.name.toLowerCase().includes(searchData)
                );
            });
            setFilteredDataTLP(filteredList);
        }
    };

    const handleSearchClick = () => {
        setShowSearch(true);
    };
    
    const handleCloseSearch = () => {
        setShowSearch(false);
        setSearchValue("");
    };

    const handleBackNavigation = () => {
        switch (type?.toLowerCase()) {
            case "topicandtest".toLowerCase():
                redirectionCall();
                break;
            case "resultPage".toLowerCase():
                break;
            case "TestSelection".toLowerCase():
                exitResponse();
                break;
            default:
                history.goBack();
                break;
        }
    }

    return (
        <div>
            <div className="d-flex sticky-top bg-dark align-item-center p-2">
                {type === "resultPage" ? <>
                    <div className="col-3 ">
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                        <a className=" cm-logo align-self-center" >
                            <Image
                                src={
                                    logo
                                }
                                className={logoClass}
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className='col-3 d-flex justify-content-end '>
                        <button className='btn ' type="button" onClick={exitResponse}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                            </svg>
                        </button>
                    </div> </>
                    :
                    <>
                        <div class="col-4">
                            <button className='btn ' type="button" onClick={handleBackNavigation}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </button>
                        </div>
                        <div class="col-4 d-flex justify-content-center ">
                            <a className="cm-logo align-self-center" >
                                <Image
                                    src={
                                        logo
                                    }
                                    className={logoClass}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                        <div className={`cm-search-container search-container ${showSearch ? "active" : "" }`}>
                            {showSearch && (
                                <>
                                    <button className='btn cm-close' type="button" onClick={handleCloseSearch}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                        </svg>
                                    </button>
                                    <input
                                        type="search"
                                        className="cm-search-input"
                                        placeholder="Search..."
                                        value={searchValue}
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                    />
                                </>
                            )}
                            {!showSearch && (
                            <button className='btn cm-close' type="button" onClick={handleSearchClick}>
                                <SearchIcon className="cm-search-icon" />
                            </button>
                            )}
                        </div>
                            {!showSearch ? (
                                <button className='btn ' type="button" onClick={exitResponse}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                    </svg>
                                </button>
                            ) : null}
                        </div>

                    </>
                }

            </div>
        </div>
    )
}
