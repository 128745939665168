import React, { useState, useEffect } from 'react'

const ClientTraingPage = ({
    history,
    location,
    match: {
        params: { type, userDetailId },
    },
}) => {

    useEffect(() => {
        userDetailId && history.push({
            pathname: `/listview/list/sub/0/Subject/${userDetailId}`,
            state: { dataDetails: userDetailId },
        });
    }, []);

    return (
        <>
        </>
    )
}

export default ClientTraingPage