import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";

import { DataConstant } from "../../../constants";
import { actionTypes } from "../../../../redux/filter/filterReducer";
import { Filter } from "../../../components/filter";
import { useCommonContext } from "../../../context/commonContext";

export const SeriLogFilter = () => {
  const { historyFilter } = useSelector(
    ({ filter }) => ({
      historyFilter: filter,
    }),
    shallowEqual
  );
  const commonContext = useCommonContext();

  const commonProps = useMemo(() => {
    return {
      queryParams: commonContext.queryParams,
      setQueryParams: commonContext.setQueryParams,
    };
  }, [commonContext]);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [startDate, setStartDate] = useState(
    historyFilter.fromDate ? new Date(historyFilter.fromDate) : null
  );
  const [endDate, setEndDate] = useState(
    historyFilter.toDate ? new Date(historyFilter.toDate) : null
  );

  const manageFilters = (event) => {
    if (event && event.target !== null) {
      //reset page to 1 when filter is applied
      commonProps.setQueryParams({ ...commonProps.queryParams, page: 1 });
      dispatch({
        type: actionTypes[event.target.name],
        payload: {
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  const handleDateChange = (date, name) => {
    const dateObj = {};
    dateObj.target = {
      name: name,
      value: date
        ? new Date(date).toLocaleDateString() +
        " " +
        new Date(date).toLocaleTimeString()
        : null,
    };
    manageFilters(dateObj);
  };

  return (
    <Form className="form form-label-right">
      <div className="form-group row mb-2">
        <div className="col-lg-3">
          <Filter />
        </div>
        <div className="col-lg-3">
          <Form.Control
            as="select"
            key="logsDD"
            name="logs"
            value={historyFilter.logs}
            onChange={(event) => {
              manageFilters(event);
            }}
          >
            {DataConstant.serilog.logs &&
              DataConstant.serilog.logs.map((entity) => (
                <option key={entity.value} value={entity.value}>
                  {entity.label}
                </option>
              ))}
          </Form.Control>
        </div>
        {historyFilter.logs?.toLowerCase() ===
          DataConstant.serilog.backOffice.toLowerCase() && (
            <>
              <div className="col-lg-3">
                <Form.Control
                  as="select"
                  key="serviceLogDD"
                  name="serviceLog"
                  value={historyFilter.serviceLog}
                  onChange={(event) => {
                    manageFilters(event);
                  }}
                >
                  {DataConstant.serilog.serviceLog &&
                    DataConstant.serilog.serviceLog.map((entity) => (
                      <option key={entity.value} value={entity.value}>
                        {entity.label}
                      </option>
                    ))}
                </Form.Control>
              </div>
              <div className="col-lg-3">
                <Form.Control
                  as="select"
                  key="levelsDD"
                  name="levels"
                  value={historyFilter.levels}
                  onChange={(event) => {
                    manageFilters(event);
                  }}
                >
                  <option key="selectLevels" value="">
                    {intl.formatMessage({ id: "serilog.level.label" })}
                  </option>
                  {DataConstant.serilog.levels &&
                    DataConstant.serilog.levels.map((entity) => (
                      <option key={entity.value} value={entity.value}>
                        {entity.label}
                      </option>
                    ))}
                </Form.Control>
              </div>
            </>
          )}
        {historyFilter.logs?.toLowerCase() ===
          DataConstant.mobileLogUI.mobileLog.toLowerCase() && (
            <div className="col-lg-3">
              <Form.Control
                as="select"
                key="MobileOSTypeDD"
                name="mobileOSType"
                value={historyFilter.mobileOSType}
                onChange={(event) => {
                  manageFilters(event);
                }}
              >
                <option key="selectMobileOSTypes" value="">
                  {intl.formatMessage({ id: "mobileLogUI.mobileOSType.label" })}
                </option>
                {DataConstant.mobileLogUI.mobileOSTypes &&
                  DataConstant.mobileLogUI.mobileOSTypes.map((entity) => (
                    <option key={entity.value} value={entity.value}>
                      {entity.label}
                    </option>
                  ))}
              </Form.Control>
            </div>
          )}
      </div>
      <div className="form-group row mb-0">
        <div className="col-lg-3">
          <DatePicker
            className="form-control w-100"
            name="fromDate"
            selected={startDate}
            dateFormat={DataConstant.dateFormats.ddmmyyyyhhmmss}
            placeholderText={intl.formatMessage({
              id: "filter.startDate.placeholder",
            })}
            showMonthDropdown
            showYearDropdown
            onChange={(e) => {
              handleDateChange(e, "fromDate");
              setStartDate(e);
            }}
          />
        </div>
        <div className="col-lg-3">
          <DatePicker
            className="form-control w-100"
            name="toDate"
            selected={endDate}
            minDate={startDate}
            dateFormat={DataConstant.dateFormats.ddmmyyyyhhmmss}
            placeholderText={intl.formatMessage({
              id: "filter.endDate.placeholder",
            })}
            showMonthDropdown
            showYearDropdown
            onChange={(e) => {
              handleDateChange(e, "toDate");
              setEndDate(e);
            }}
          />
        </div>
      </div>
    </Form>
  );
};
