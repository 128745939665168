import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { handleFetchPost } from '../../../utils/apiCallsProvider';
import { LayoutSplashScreen } from '../../../_metronic/layout';
import { ErrorComponent } from '../../components/errorComponent';
import { DataConstant, UrlConstant } from '../../constants';
import { Header } from './header/header';

export const TrainingTestQuestionPage = ({ history, location, match: {
    params: {
        userTrainingSubjectProgressId, userTrainingSubjectTestAndTopicProgressId, detailName }
} }) => {

    const methods = useForm({ mode: "all" });
    const intl = useIntl();

    const { setValue, register, handleSubmit, control, ref, formState: { errors },

    } = methods;


    const question = {
        label: intl.formatMessage({ id: "coupon.status.label" }),
        name: "question.status",
        validations: {
            required: intl.formatMessage(
                { id: "trainingTest.selectAtleastOneAnswer.message" }
            ),
        },
    };

    const { state: { dataDetails } } = location;
    const [isApiCalled, setIsApiCalled] = useState(false);

    const onSubmit = data => {

        let checkBoxAnswer = [];
        let radioAnswer = [];

        let userAnsweData = _.map(data, (i) => {
            if (_.isArray(i)) {
                checkBoxAnswer.push(_.map(i, (j) => JSON.parse(j)))
            }
            else { radioAnswer.push(JSON.parse(i)) }

        })
        let allAnswer = _.flatten(_.merge([radioAnswer, _.flatten(checkBoxAnswer)]));
        let grouedQuestionAnswer = _.groupBy(allAnswer, "testQuestionId");

        let requestParam = _.map(grouedQuestionAnswer, ans => {
            if (ans.length > 0) {
                let userAnswerObj = {
                    "userTrainingTestQuestionAnswerProgressId": ans?.[0]?.userTrainingTestQuestionAnswerProgressId,
                    "testQuestionId": ans?.[0]?.testQuestionId,
                    "submittedAnswers": _.map(ans, "testQuestionAnswerId"),
                }
                return userAnswerObj;
            }
        });

        setIsApiCalled(true);

        handleFetchPost(
            UrlConstant.clientTraining.submitTestQuestionAnswer,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            setIsApiCalled(false);
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {
                let data = response?.data?.userTestResult;

                data.userDetailId = dataDetails.userDetailId;

                history.push({
                    pathname: `/clientTraining/trainingResultPage/${userTrainingSubjectProgressId}/${detailName}`,
                    state: { answerDetail: { ...response?.data, dataDetails: dataDetails } },
                });
            }
        });

    };

    const [displayList, setDisplayList] = useState([]);

    const getQuestionList = () => {

        let requestParam = {
            userTrainingSubjectTestAndTopicProgressId: userTrainingSubjectTestAndTopicProgressId,
        };
        handleFetchPost(
            UrlConstant.clientTraining.getUserTrainingTestQuestionList,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {
                //Note: for testing purpose.
                let data = response.data;
                data.userTestQuestionList = _.map(response?.data?.userTestQuestionList, (item) => {
                    return {
                        ...item,
                        answerType: _.toLower(item.answersType) === _.toLower(DataConstant.trainingSubject.answerTypes.singleChoiceQuestion) ? "radio" : "checkbox",

                    }
                })
                setDisplayList(data);
            }
            setIsApiCalled(false);
        });
    };

    const TrainingQuestionPage = () => {
        return (
            <div class="overflow-hidden">

                {displayList?.userTestQuestionList && displayList?.userTestQuestionList?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className="form-check">
                                <span className="font-weight-bolder d-flex text-dark font-size-h3 mb-0 d-block ">
                                    <span>{index + 1 + "."}</span><p>&nbsp;</p>
                                    <div dangerouslySetInnerHTML={{ __html: (item?.question) }}>
                                    </div>
                                </span>
                                <div className="col-xl-6">
                                    <div className="form-check">
                                        {item?.testQuestionAnswerList?.map((option, index) => {
                                            return (
                                                <>
                                                    <div className="form-check" key={index}>
                                                        <label htmlFor={option?.testQuestionAnswerId}>
                                                            <input
                                                                {...register(`${item?.testQuestionId}`, question?.validations)}
                                                                type={item?.answerType}
                                                                value={JSON.stringify({ ...option, userTrainingTestQuestionAnswerProgressId: item?.userTrainingTestQuestionAnswerProgressId })}
                                                                className="form-check-input"
                                                            />{' '}
                                                            <span className="font-weight-normal text-dark font-size-h5 p-2 ">
                                                                {option?.value}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                                <ErrorComponent errors={errors} name={`${item?.testQuestionId}`} />
                            </div>
                            <br />
                        </div>)
                }
                )}
            </div>
        )
    };

    useEffect(() => {
        setIsApiCalled(true);
        getQuestionList()
    }, [])
    return (
        <div class="overflow-hidden">
            <LayoutSplashScreen visible={isApiCalled} />
            <Header history={history} type="TestQuestion" />
            <div>
                <div className='p-5'>
                    <span className="font-weight-bolder text-dark font-size-h2 ">
                        {detailName}
                    </span>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="cm-training-wrapper">
                <div className="form-group">
                    <div className='p-5'>
                        <br />
                        <TrainingQuestionPage />
                    </div>
                    <button cls class={`btn btn-primary btn-lg btn-block fixed-bottom ${isApiCalled ? 'd-none' : ''} `} type="submit" value="submit">
                        {intl?.formatMessage({ id: "client.training.test.submitAnswer" })}
                    </button>
                </div>
            </form>
        </div>
    )
}
