import * as CryptoJS from "crypto-js";
import moment from "moment";
import { DataConstant } from "../app/constants/dataConstant";
import _ from "lodash";

export const EncryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const encdata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encdata.toString();
};

export const DecryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const validateTwoDecimalOnly = (e, places = 3) => {
  let t = e.target.value.toString();
  if (t.indexOf(".") >= 0) {
    t = t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), places)
    e.target.value = t;
  }
};

export const validateNoDecimal = (e) => {
  ignoreSignInNumber(e);
  if (e.keyCode === 190 || e.keyCode === 110) {
    e.preventDefault();
    return false;
  }
};

export const ignoreSignInNumber = (e) => {
  let kc = e.keycode || e.which;
  if (kc === 107 || kc === 109 || kc === 189 || kc === 187) {
    e.preventDefault();
    return false;
  } else return true;
};
export const validateOnlyNumber = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const validateOnlyNumberWithDot = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46 || x === 110 || x === 190
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const handleEmptySpace = (e) => {
  let t = e.target.value.trim();
  e.target.value = t ? e.target.value : null;
};

export const assignMomentDateTime = (dateTime, browser) => {

  switch (browser?.browserPlatform) {
    case DataConstant.browserPlatform.MacIntel:
      return moment(String(dateTime), DataConstant.dateFormats.mmddyyyy).format(
        DataConstant.dateFormats.ddmmyyyy
      );

    default:
      return moment(String(dateTime)).format(
        DataConstant.dateFormats.ddmmyyyy
      );
  }

}

export const setMomentDateTime = (dateTime) => {
  if (window.navigator.platform.toLowerCase().includes('win') && (typeof InstallTrigger !== 'undefined') === false) {
    return moment(dateTime).format(
      DataConstant.dateFormats.ddmmyyyy
    )
  }
  else {
    if (moment(dateTime, DataConstant.dateFormats.ddmmyyyy).isValid()) {
      return moment(String(dateTime), DataConstant.dateFormats.ddmmyyyy).format(DataConstant.dateFormats.ddmmyyyy);
    }
    else {
      return moment(String(dateTime), "").format(DataConstant.dateFormats.ddmmyyyy);
    }
  }
}


export const IsValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (isNaN(date)) { // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return false;
    } else {
      // date object is valid
      return true;
    }
  } else {
    // not a date object
    return false;
  }
}

export const subtractMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
}

export const addMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
}

export const convertToLocalTime = (datestring, format = DataConstant.dateFormats.mmddyyyyHHmmss, displayFormat = DataConstant.dateFormats.mmddyyyy_hhmmA) => {
  if (!datestring) return '-';
  var utcDateTime = moment.utc(datestring, format)
  return moment(utcDateTime).local().format(displayFormat);
}

export const convertToLocalFromUTC = (datestring, displayFormat = DataConstant.dateFormats.mmddyyyy_hhmmA) => {
  if (!datestring) return '-';
  var utcDateTime = moment.utc(datestring)
  return moment(utcDateTime).local().format(displayFormat);
}

export const sortOnKey = (_array, keyName) => {
  if (keyName === null || keyName === undefined) return _array;

  return _array.sort((a, b) => {
    if (a[keyName] < b[keyName]) { return -1 }
    else if (a[keyName] > b[keyName]) { return 1 }
    else return 0;
  })

}

export const convertPhraseToURLCompatible = (_string) => {

  _string = _string?.replace('%', 'PERCNET');

  _string = encodeURIComponent(_string);

  return _string;

}
export const convertURLToPhraseCompatible = (_string) => {

  _string = decodeURIComponent(_string);

  _string = _string?.replace('PERCNET', '%');

  return _string;
}
export const IsValidNumber = (value) => {
  let isvalid = true;
  if (value === null || value === undefined || value === 0) {
    isvalid = false;
  }
  return isvalid;
};

export const getValidDateOnCrossPlatform = (_date, _format = DataConstant.dateFormats.ddmmyyyy) => {

  //to generate local date format
  var now = new Date(2023, 11, 31);
  var localformat = now.toLocaleDateString();
  localformat = localformat.replace("31", "DD");
  localformat = localformat.replace("12", "MM");
  localformat = localformat.replace("2023", "YYYY");

  return moment(_date, localformat).format(_format);
}

export const convertListToDropdownList = (_list, _labelKeyName, _valueKeyName) => {
    if (_list && _list.length > 0 && _labelKeyName && _valueKeyName) {
        return _list.map((_item) => {
            return {
                label: _item[_labelKeyName],
                value: _item[_valueKeyName]
            }
        })

    }
    else return [];
}

/**
 * Converts a numerical value to its ordinal representation appended with a provided index.
 * @param {number} index - The numerical value to be converted to its ordinal representation.
 * @returns {string} The ordinal representation of the value appended with the index.
 * @example
 * // Example usage:
 * console.log(getNthValueText(22)); // Output: "22nd"
 * console.log(getNthValueText(17)); // Output: "17th"
 * console.log(getNthValueText(3)); // Output: "3rd"
 * console.log(getNthValueText(31)); // Output: "31st"
 */
export const getNthValueText = (index=0) => {
	if (!index) return "";
	const suffixes = ["th", "st", "nd", "rd"];
	const intValue = parseInt(index, 10);
	const suffix = (intValue % 100 > 10 && intValue % 100 < 14) ? suffixes[0] : suffixes[intValue % 10] || suffixes[0];
	return `${intValue}${suffix}`;
};
