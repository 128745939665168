import React from 'react';
import { Header } from './header/header';
import './ckEditorCss.css'

export const TrainingTopicStepInfo = (props) => {
    const { location, history, match } = props;
    const { params: { topicStepId } } = match
    const { state: { stepDetail } } = location

    return (
        <div class="overflow-hidden">
            <Header history={history} />
            <div key={0}>
                <div>
                    <br />
                    <div className='p-5'>
                        <h3>
                            <b> {stepDetail?.name} </b>
                        </h3>
                    </div>
                    <p className='cm-training-wrapper font-size-h3'>
                        <div className='p-5' dangerouslySetInnerHTML={{ __html: stepDetail?.description }}>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    )
}