import { DataConstant } from "./constants";
const { REACT_APP_WHITELABELNAME } = process.env;

const LightenDarkenColor = (col, amt) => {
    var usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

export const whitelabelThemeConfig = () => {

    var scssConfig = {
        primary: "",
        secondary: "",
        grayOrDisable: "",
        infoBox: "",
        tertiary: "",
        activeClassBackgroundColor: "",

    };

    switch (REACT_APP_WHITELABELNAME?.toLowerCase()) {

        case DataConstant.whiteLabelNames.telemedicine.name.toLowerCase():
            scssConfig = {
                primary: "#1e1e2d",
                secondary: "#3699ff",
                grayOrDisable: "#aa32ff",
                infoBox: "#e1f0ff",
                tertiary: "#a2a3b7",
                activeClassBackgroundColor: "#69b3ff",
            };

            break;
        case DataConstant.whiteLabelNames.dripbar.name.toLowerCase():
            scssConfig = {
                primary: "#2b515c",
                secondary: "#a1a438",
                grayOrDisable: "#aa32ff",
                infoBox: "#e1f0ff",
                tertiary: "#a2a3b7",
                activeClassBackgroundColor: "#a1a438",
            };
            break;
        case DataConstant.whiteLabelNames.hydreight.name.toLowerCase():
            scssConfig = {
                primary: "#2b4168",
                secondary: "#5d9cec",
                grayOrDisable: "#aa32ff",
                infoBox: "#e1f0ff",
                tertiary: "#a2a3b7",
                activeClassBackgroundColor: "#69b3ff",
            };
            break;
        case DataConstant.whiteLabelNames.milTeste.name.toLowerCase():
            scssConfig = {
                primary: "#6767a9",
                secondary: "#6767a9",
                grayOrDisable: "#aa32ff",
                infoBox: "#e5e5fd",
                tertiary: "#d4d5e3",
                activeClassBackgroundColor: "#6767a9",
            };
            break;
        case DataConstant.whiteLabelNames.metaVet.name.toLowerCase():
            scssConfig = {
                primary: "#2D575F",
                secondary: "#007157",
                grayOrDisable: "#aa32ff",
                infoBox: "#B3DCD7",
                tertiary: "#d4d5e3",
                activeClassBackgroundColor: "#2D575F",
            };
            break;
        case DataConstant.whiteLabelNames.vsdigital.name.toLowerCase():
            scssConfig = {
                primary: "#325473",
                secondary: "#6896C1",
                grayOrDisable: "#aa32ff",
                infoBox: "#A6C3DE",
                tertiary: "#d4d5e3",
                activeClassBackgroundColor: "#6896C1",
            };
            break;
        default:
            break;
    }



    document.documentElement.style.setProperty('--default-icon-primary', scssConfig.secondary);
    document.documentElement.style.setProperty('--default-fa-edit', scssConfig.secondary);

    document.documentElement.style.setProperty('--default-fa-clone', scssConfig.secondary);
    document.documentElement.style.setProperty('--default-fa-dis', scssConfig.secondary);

    document.documentElement.style.setProperty('--default-aside-hover', LightenDarkenColor(scssConfig.primary, -3));
    document.documentElement.style.setProperty('--default-aside-theme', scssConfig.primary);

    document.documentElement.style.setProperty('--default-aside-highilted-theme', LightenDarkenColor(scssConfig.primary, -10)); // for highlight aside
    document.documentElement.style.setProperty('--default-brand', LightenDarkenColor(scssConfig.primary, -7)); // for highlight aside

    document.documentElement.style.setProperty('--default-asideText', scssConfig.tertiary);
    document.documentElement.style.setProperty('--default-activeRingColor', scssConfig.activeClassBackgroundColor);

    document.documentElement.style.setProperty('--default-info-color', scssConfig.infoBox);
    document.documentElement.style.setProperty('--default-datePicker-theme', scssConfig.secondary);
    document.documentElement.style.setProperty('--default-fa-eye', scssConfig.secondary);
    document.documentElement.style.setProperty('--ck-color-base-active-focus', scssConfig.activeClassBackgroundColor);
    document.documentElement.style.setProperty('--default-teg-color', LightenDarkenColor(scssConfig.primary, 20));
}