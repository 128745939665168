import React, { useEffect, useState } from 'react'
import { Header } from './header/header'
import { handleFetchPost } from '../../../utils/apiCallsProvider'
import { DataConstant, UrlConstant } from '../../constants'
import { LayoutSplashScreen } from "../../../_metronic/layout";
import _ from "lodash";
import { useIntl } from "react-intl";

export const TrainingTestSelectionPage = ({ location, history, match: {
    params: { subjectTestAndTopicId, userTrainingSubjectProgressId }

} }) => {
    const handlePage = (e) => {
        history.push({
            pathname: `/clientTraining/trainingQuestionPage/${userTrainingSubjectProgressId}/${e.name}/${e.userTrainingSubjectTestAndTopicProgressId}`,
            state: { dataDetails: { ...e, subjectName: dataDetails?.subjectName } },
        });
    }

    const { state: { dataDetails } } = location;
    const [displayList, setDisplayList] = useState([]);
    const [isApiCalled, setIsApiCalled] = useState(false);
    const intl = useIntl();

    const clientTrainingResult = DataConstant.clientTrainingResult.progressStatus;

    const getTestList = () => {

        let requestParam = {
            subjectTestAndTopicId: subjectTestAndTopicId,
            userTrainingSubjectProgressId: userTrainingSubjectProgressId,
        };
        handleFetchPost(
            UrlConstant.clientTraining.getUserTrainingTestDetail,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {

                let data = response.data;
                data.userDetailId = dataDetails.userDetailId;
                setDisplayList(response.data);
            }
            setIsApiCalled(false)
        });
    };

    useEffect(() => {
        setIsApiCalled(true)
        getTestList()
    }, [])
    return (
        <div class="overflow-hidden">
            <LayoutSplashScreen visible={isApiCalled} />
            <Header history={history} />
            <div className="p-5">
                <span className="font-weight-bolder text-dark font-size-h2 ">
                    {displayList?.name}
                </span>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{intl.formatMessage({ id: "client.training.test.selection.title1" })}: {displayList?.passingScore}%</h5>
                        {clientTrainingResult && clientTrainingResult.map((item, index) => {
                            return (<>
                                {_.toLower(displayList?.progressStatus) == _.toLower(item?.value) ?
                                    <p class="card-text text-success">{intl.formatMessage({ id: "client.training.test.selection.title2" })} : {displayList?.progressStatus}</p> : <p class="card-text text-warning">{intl.formatMessage({ id: "client.training.test.selection.title2" })} : {displayList?.progressStatus}</p>}
                                {_.toLower(displayList?.progressStatus) == _.toLower(item.value) ?
                                    null : <button type="button" onClick={() => handlePage(displayList)} class="btn btn-primary">{intl.formatMessage({ id: "client.training.test.selection.title3" })}</button>}
                            </>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

