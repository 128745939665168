import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { LoaderSlice } from "./loader/loaderSlice";
import { filterReducer } from "./filter/filterReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loader: LoaderSlice.reducer,
  filter: filterReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
