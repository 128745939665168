import React from "react";

const status = {
  Information: "info",
  Warning: "warning",
  Error: "danger",
};

export function SerilogLevelFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${status[row.level?.replace(/\s/g, "")]
      } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row.level}
    </span>
  );
}
