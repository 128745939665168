/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import * as authProvider from "../../../../../utils/authProvider";
import { Button } from "react-bootstrap";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  var firstName = localStorage.getItem("firstName");
  var lastName = localStorage.getItem("lastName");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const changePassword = () => {
    authProvider.changePassword();
  };


  const signOut = () => {
    authProvider.signOut();
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 cm-header-content-name-wrapper">
            {firstName} {lastName}
          </span>
          <span className="svg-icon svg-icon-md svg-icon-danger mr-5">
            <i className="fa fa-user " />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {firstName} {lastName}
                </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top popup-view"
            >
              <div className="symbol bg-white-o-15 mr-3"></div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {firstName} {lastName}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="" className="navi-item px-8 cursor-pointer">
            <div className="navi-link" onClick={changePassword}>
              <div className="navi-icon mr-2">
                <i className="flaticon-lock text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Change Password
                </div>
                <div className="text-muted"></div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>
          <div className="navi-footer  px-8 py-5">
            <Button
              className="btn btn-primary font-weight-bold"
              onClick={signOut}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
