import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetUserDetail: "[Set UserDetail] Action",
  SetBrowerDetail: "[Set BrowerDetail] Action",
  SetAuthToken: "[Set AuthToken] Action",
  SetIsAuthorizedByAPI: "[Set IsAuthorizedByAPI] Action",
  //SetIsWithDoctorNetwork: "[Set IsWithDoctorNetwork] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  isSigninInProgress: false,
  userDetailId: undefined,
  userType: undefined,
  userTypeId: undefined,
  businessId: undefined,
  businessName: undefined,
  whiteLabel: undefined,
  currency: undefined,
  allowBusinessToAddMembership: undefined,
  browser: undefined,
  allowCashPayment: undefined,
  isAuthorizedByAPI: undefined,
  isWithDoctorNetwork: undefined,
  firstName: undefined,
  lastName: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: "v713-demo1-auth",
    whitelist: ["user", "authToken", "isSigninInProgress", "userType"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {
          authToken,
          user,
          userName,
          isSigninInProgress,
        } = action.payload;

        return { authToken, user, userName, isSigninInProgress };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUserDetail: {
        const {
          userDetailId,
          userType,
          userTypeId,
          businessName,
          businessId,
          whiteLabel,
          currency,
          allowBusinessToAddMembership,
          allowCashPayment,
          isWithDoctorNetwork,
          firstName,
          lastName
        } = action.payload;
        return {
          ...state,
          userDetailId,
          userType,
          userTypeId,
          businessName,
          businessId,
          whiteLabel,
          currency,
          allowBusinessToAddMembership,
          allowCashPayment,
          isWithDoctorNetwork,
          firstName,
          lastName
        };
      }

      case actionTypes.SetBrowerDetail: {
        const { browser } = action.payload;
        return { ...state, browser };
      }

      case actionTypes.SetAuthToken: {
        const { authToken } = action.payload;
        return { ...state, authToken };
      }

      case actionTypes.SetIsAuthorizedByAPI: {
        const { isAuthorizedByAPI } = action.payload;
        return { ...state, ...isAuthorizedByAPI };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  SetUserDetail: (userType) => ({
    type: actionTypes.SetUserDetail,
    payload: { userType },
  }),
  SetIsAuthorizedByAPI: (isAuthorizedByAPI) => ({
    type: actionTypes.SetIsAuthorizedByAPI,
    payload: { isAuthorizedByAPI },
  }),

};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();

  //   yield put(actions.fulfillUser(user));
  // });
}
