export const TenantConfig = {
  telemedicine: {
    clientId: "35cf5889-ab8d-4582-a3a7-34fcf5c5a876",
    authority: "https://telemedicinedev.b2clogin.com",
    signUpSignIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignIn",
    redirectURL: "https://telemedicine.localhost:3000/authorize/callback",
    postLogoutRedirectURL: "https://telemedicine.localhost:3000/auth/login",
    loginScope:
      "https://telemedicinedev.onmicrosoft.com/ecbce02b-6a45-411d-8d1c-c2a487e4b932/api_read",
    resetPassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  telemedicine2: {
    clientId: "e4d3a99f-54a2-4829-842a-c74a651311bf",
    authority: "https://telemedicinedev2.b2clogin.com",
    signUpSignIn:
      "https://telemedicinedev2.b2clogin.com/telemedicinedev2.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignIn",
    redirectURL: "https://telemedicine2.localhost:3000/authorize/callback",
    postLogoutRedirectURL: "https://telemedicine2.localhost:3000/auth/login",
    loginScope:
      "https://telemedicinedev2.onmicrosoft.com/c7d9aa67-a910-4540-8875-d5a533105777/api_read",
    resetPassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "telemedicine-dev-ui": {
    clientId: "35cf5889-ab8d-4582-a3a7-34fcf5c5a876",
    authority: "https://telemedicinedev.b2clogin.com",
    signUpSignIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://telemedicine-dev-ui.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://telemedicine-dev-ui.azurewebsites.net/auth/login",
    loginScope:
      "https://telemedicinedev.onmicrosoft.com/ecbce02b-6a45-411d-8d1c-c2a487e4b932/api_read",
    resetPassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  telemedicinedripbar: {
    clientId: "c7365d93-33db-4393-a7ae-762d1ef1e465",
    authority: "https://telemeddripbar.b2clogin.com",
    signUpSignIn:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://telemedicinedripbar.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://telemedicinedripbar.azurewebsites.net/auth/login",
    loginScope:
      "https://telemeddripbar.onmicrosoft.com/d7fefea9-ed2f-415c-9cae-7eddea424699/api_read",
    resetPassword:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "dripbar-backoffice-prod": {
    clientId: "df0e2df5-32bd-41b2-8336-163a9af88152",
    authority: "https://dripbarprod.b2clogin.com",
    signUpSignIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://dripbar-backoffice-prod.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://dripbar-backoffice-prod.azurewebsites.net/auth/login",
    loginScope:
      "https://dripbarprod.onmicrosoft.com/3e597de9-e0ce-4faf-99a1-f6669816b68c/api_read",
    resetPassword:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "admin-backoffice": {
    clientId: "df0e2df5-32bd-41b2-8336-163a9af88152",
    authority: "https://dripbarprod.b2clogin.com",
    signUpSignIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://admin-backoffice.thedripbar.com/authorize/callback",
    postLogoutRedirectURL:
      "https://admin-backoffice.thedripbar.com/auth/login",
    loginScope:
      "https://dripbarprod.onmicrosoft.com/3e597de9-e0ce-4faf-99a1-f6669816b68c/api_read",
    resetPassword:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "hydreight-backoffice-test": {
    clientId: "f8f47b34-330b-42fe-983b-ffdc1fb3007a",
    authority: "https://telemedhydreighttest.b2clogin.com",
    signUpSignIn:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://hydreight-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://hydreight-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://telemedhydreighttest.onmicrosoft.com/f9baf533-adb1-4c0d-b814-88930dca3af7/api_read",
    resetPassword:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "hydreight-backoffice-prod": {
    clientId: "e914993a-c79a-4724-ba02-12ca15f5a38f",
    authority: "https://hydreightprod.b2clogin.com",
    signUpSignIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://backoffice-admin.hydreight.com/authorize/callback",
    postLogoutRedirectURL:
      "https://backoffice-admin.hydreight.com/auth/login",
    loginScope:
      "https://hydreightprod.onmicrosoft.com/b221b833-f8b2-4c7d-a404-94464e5bc411/api_read",
    resetPassword:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "backoffice-admin": {
    clientId: "e914993a-c79a-4724-ba02-12ca15f5a38f",
    authority: "https://hydreightprod.b2clogin.com",
    signUpSignIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://backoffice-admin.hydreight.com/authorize/callback",
    postLogoutRedirectURL:
      "https://backoffice-admin.hydreight.com/auth/login",
    loginScope:
      "https://hydreightprod.onmicrosoft.com/b221b833-f8b2-4c7d-a404-94464e5bc411/api_read",
    resetPassword:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword: "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "milteste-backoffice-test": {
    clientId: "82a9c08b-36dc-432d-83ce-1ae46f2519ad",
    authority: "https://miltestetest.b2clogin.com",
    signUpSignIn:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://milteste-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://milteste-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://miltestetest.onmicrosoft.com/7e5e4e29-cb47-4302-9e90-9e0dadab905b/api_read",
    resetPassword:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "milteste-backoffice-prod": {
    clientId: "82a9c08b-36dc-432d-83ce-1ae46f2519ad",
    authority: "https://miltestetest.b2clogin.com",
    signUpSignIn:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://milteste-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://milteste-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://miltestetest.onmicrosoft.com/7e5e4e29-cb47-4302-9e90-9e0dadab905b/api_read",
    resetPassword:
      "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://miltestetest.b2clogin.com/miltestetest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "metavet-backoffice-test": {
    clientId: "e4f691ec-e5bf-448f-80af-8e22c8da69d2",
    authority: "https://metavettest.b2clogin.com",
    signUpSignIn:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://metavet-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://metavet-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://metavettest.onmicrosoft.com/97cf4f77-8af4-4b17-b178-3b0c91932dc9/api_read",
    resetPassword:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "metavet-backoffice-prod": {
    clientId: "e4f691ec-e5bf-448f-80af-8e22c8da69d2",
    authority: "https://metavettest.b2clogin.com",
    signUpSignIn:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://metavet-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://metavet-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://metavettest.onmicrosoft.com/97cf4f77-8af4-4b17-b178-3b0c91932dc9/api_read",
    resetPassword:
      "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://metavettest.b2clogin.com/metavettest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "vsdigital-backoffice-test": {
    clientId: "5ffe1549-f428-405a-a596-dc493d0a6263",
    authority: "https://vsdigitaltest.b2clogin.com",
    signUpSignIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://vsdigital-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://vsdigital-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://vsdigitaltest.onmicrosoft.com/e360dc2-8a7e-487d-a605-089b9453118e/api_read",
    resetPassword:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  },
  "vsdigital-backoffice-prod": {
    clientId: "5ffe1549-f428-405a-a596-dc493d0a6263",
    authority: "https://vsdigitaltest.b2clogin.com",
    signUpSignIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignUp_SignIn",
    signIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignIn",
    redirectURL:
      "https://vsdigital-backoffice-test.azurewebsites.net/authorize/callback",
    postLogoutRedirectURL:
      "https://vsdigital-backoffice-test.azurewebsites.net/auth/login",
    loginScope:
      "https://vsdigitaltest.onmicrosoft.com/e360dc2-8a7e-487d-a605-089b9453118e/api_read",
    resetPassword:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_Password_Reset",
    passwordReset: "B2C_1_Password_Reset",
    changePassword: "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
  }
};
