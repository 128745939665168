import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import * as authentication from '../../../../utils/authProvider'

function Login(props) {
  const [loading, setLoading] = useState(false);

  const handleSignIn = () => {
    authentication.signIn();
  }

  setTimeout(() => {
    handleSignIn();
  }, 2000);
  return (<></>
    // <div className="login-form login-signin" id="kt_login_signin_form">
    //   <button
    //     id="kt_login_signin"
    //     type="button"
    //     disabled={loading}
    //     className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
    //     onClick={handleSignIn}
    //   >
    //     <span>
    //       {/* <img
    //         alt="Logo"
    //         className="max-h-70px mr-3"
    //         src={toAbsoluteUrl("/media/project-logos/azuread.png")}
    //         width={35}
    //       /> */}
    //       Sign In
    //     </span>
    //     {loading && <span className="ml-3 spinner spinner-white"></span>}
    //   </button>
    // </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Login));
