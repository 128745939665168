export const MessageConstant = {
  addSuccess: "{0} saved successfully.",
  updateSuccess: "{0} updated successfully.",
  deleteSuccess: "{0} deleted successfully.",
  addFailure: "Error in saving {0}.",
  duplicateRecordFound: "{0} already exist.",
  recordNotFound: "{0} not found.",
  imageUploadSuccess: "{0} image upload successfully.",
  imageUploadFailed: "{0} image upload failed.",
  picUpdateFailed: "{0} image update failed.",
  picDeleteSuccess: "{0} image deleted successfully.",
  picDeleteFailed: "{0} image delete failed.",
  docUploadSuccess: "{0} document upload successfully.",
  docUploadFailed: "{0} document upload failed.",
  docDeleteSuccess: "{0} document deleted successfully.",
  docDeleteFailed: "{0} document delete failed.",
  selectImageValidation: "Please select image file.",
  selectCsvValidation: "Please select csv file.",
  serviceCatgeory: {
    serviceCategoryRunning: "Service Category is in use.",
  },
  requiredField: "{0} is required.",
  maxCharLimit: "{0} accept only {1} characters.",
  alphaNumericValidation: "{0} must be alphaNumeric.",
  selectionRequired: "Please select {0}.",
  maxLengthValidation: "{0} accept only {1} characters.",

  deleteConfirmation: "Are you sure to delete record?",
  multiDeleteConfirmation: "Are you sure to delete record(s)?",
  business: {
    netProfitValueValidation: "Net profit total must be 100%",
  },
};
