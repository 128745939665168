import React, { useMemo, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { FormattedMessage } from "react-intl";

import {
  getSelectRow,
  getHandlerTableChange,
  PleaseWaitMessage,
} from "../../_metronic/_helpers";
import { Pagination } from "../../_metronic/_partials/controls";
import { DataConstant } from "../constants/dataConstant";
import { useCommonContext } from "../context/commonContext";

export const GridComponent = (props) => {
  const { entities, columns, totalCount, keyField, hideSelection, className, hideFooter } = props;

  const commonContext = useCommonContext();
  const commonProps = useMemo(() => {
    return {
      queryParams: commonContext.queryParams,
      setQueryParams: commonContext.setQueryParams,
      ids: commonContext.ids,
      setIds: commonContext.setIds,
    };
  }, [commonContext]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      <FormattedMessage
        id="grid.pagination.customTotal"
        values={{ from, to, size }}
      />
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: DataConstant.sizePerPageList,
    sizePerPage: commonProps.queryParams.itemsPerPage,
    page: commonProps.queryParams.page,
    paginationTotalRenderer: customTotal,
  };

  useEffect(() => {
    commonProps.setIds([]);
  }, []);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>{hideFooter == true ? (
              <BootstrapTable
                condensed
                wrapperClasses={`table-responsive ${className}`}
                striped
                classes="table table-head-custom table-vertical-center overflow-hidden cm-table-striped"
                bootstrap4
                remote
                keyField={keyField || "id"}
                data={entities?.length > 0 ? entities : []}
                columns={columns}
                defaultSorted={DataConstant.defaultSorted}
                onTableChange={getHandlerTableChange(
                  commonProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities: entities?.length > 0 ? entities : [],
                  ids: commonProps.ids,
                  setIds: commonProps.setIds,
                  keyField: keyField || "id",
                  hideSelection
                })}
                noDataIndication={() => (
                  <div className="text-center">
                    <FormattedMessage id="grid.noRecordsFound" />
                  </div>
                )}
                {...paginationTableProps}
              >
                <PaginationListStandalone {...paginationProps} />
                <PleaseWaitMessage entities={entities} />
              </BootstrapTable>
            ) : (<Pagination paginationProps={paginationProps}>
              <BootstrapTable
                condensed
                wrapperClasses={`table-responsive ${className}`}
                striped
                classes="table table-head-custom table-vertical-center overflow-hidden cm-table-striped"
                bootstrap4
                remote
                keyField={keyField || "id"}
                data={entities?.length > 0 ? entities : []}
                columns={columns}
                defaultSorted={DataConstant.defaultSorted}
                onTableChange={getHandlerTableChange(
                  commonProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities: entities?.length > 0 ? entities : [],
                  ids: commonProps.ids,
                  setIds: commonProps.setIds,
                  keyField: keyField || "id",
                  hideSelection
                })}
                noDataIndication={() => (
                  <div className="text-center">
                    <FormattedMessage id="grid.noRecordsFound" />
                  </div>
                )}
                {...paginationTableProps}
              >
                <PaginationListStandalone {...paginationProps} />
                <PleaseWaitMessage entities={entities} />
              </BootstrapTable>
            </Pagination>)}
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
};
