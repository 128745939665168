/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ClientTraingPage from "./modules/clientTraining/clientTraingPage";
import TariningListPage from "./modules/clientTraining/tariningListPage";
import { TrainingResultPage } from "./modules/clientTraining/trainingResultPage";
import { TrainingSubjectList } from "./modules/clientTraining/trainingSubjectList";
import { TrainingTestQuestionPage } from "./modules/clientTraining/trainingTestQuestionPage";
import { TrainingTestSelectionPage } from "./modules/clientTraining/trainingTestSelectionPage";
import { TrainingTopicStepInfo } from "./modules/clientTraining/trainingTopicStepInfo";
import DevPaymentPage from "./modules/payment/devPaymentPage";
import LocalPaymentPage from "./modules/payment/localPaymentPage";
import PaymentPage from "./modules/payment/paymentPage";
import ThirdPartyDevPaymentPage from "./modules/payment/thirdPartyDevPaymentPage";
import ThirdPartyLocalPaymentPage from "./modules/payment/thirdPartyLocalPaymentPage";
import ThirdPartyPaymentPage from "./modules/payment/thirdPartyPaymentPage";
import SeriLogPage from "./modules/serilog/seriLogPage";
import UserTypeSelectionPage from "./modules/userType/userTypeSelectionPage";
import { whitelabelThemeConfig } from "./whitelabelThemeConfig";

import { Button, Modal, } from "react-bootstrap";
import { FormattedMessage, } from "react-intl";
import { signOut } from "../utils/authProvider";

export function Routes() {
  const { isAuthorized, isSigninInProgress, userType, isAuthorizedByAPI } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
      userType: auth.userType,
      isAuthorizedByAPI: auth.isAuthorizedByAPI
    }),
    shallowEqual
  );

  whitelabelThemeConfig();

  const UnauthorizedSignoutPopup = () => {
    return (
      <div>
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}>
          <Modal.Body>
            <FormattedMessage id="unauthorized.message" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={signOut}
            >
              <FormattedMessage id="button.signout" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  return (
    <Switch>
      <Route path="/payment" component={PaymentPage} />
      <Route path="/thirdpartypayment" component={ThirdPartyPaymentPage} />

      <Route path="/devpayment" component={DevPaymentPage} />
      <Route
        path="/thirdpartydevpayment"
        component={ThirdPartyDevPaymentPage}
      />
      <Route path="/localpayment" component={LocalPaymentPage} />
      <Route
        path="/thirdpartylocalpayment"
        component={ThirdPartyLocalPaymentPage}
      />

      {/* redirect to List from start */}
      <Route
        path="/clienttrainning/user/:type/:userTrainingSubjectProgressId/:detailName/:detailId"
        component={TariningListPage}
      />

      <Route
        path="/listview/list/:type/:userTrainingSubjectProgressId/:detailName/:detailId"
        component={TrainingSubjectList}
      />

      {/* redirect to step Page description */}
      <Route
        path="/clienttrainning/stepsInfo/:topicStepId"
        component={TrainingTopicStepInfo}
      />

      {/* redirect to Test List */}
      <Route
        path="/clienttrainning/test/:detailName/:userTrainingSubjectProgressId/:subjectTestAndTopicId"
        component={TrainingTestSelectionPage}
      />

      {/* redirect to question Page */}
      <Route
        path="/clientTraining/trainingQuestionPage/:userTrainingSubjectProgressId/:detailName/:userTrainingSubjectTestAndTopicProgressId"
        component={TrainingTestQuestionPage}
      />

      <Route
        //path="/clientTraining/trainingResultPage/:userTypeId/:userDetailId/:detailName/:userTrainingSubjectProgressId/:subjectTestAndTopicId/:userTrainingSubjectTestAndTopicProgressId/"
        path="/clientTraining/trainingResultPage/:userTrainingSubjectProgressId/:detailName"
        component={TrainingResultPage}
      />

      {/* First Page For Redirection */}
      <Route
        path="/clienttrainning/:userTypeId/:userDetailId"
        component={ClientTraingPage}
      />

      <Route path="/serilog" component={SeriLogPage} />

      {!isAuthorized && !isSigninInProgress ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      {!isAuthorized && isSigninInProgress ? (
        /*Render layoutsplashscreen page when login in process.*/
        <LayoutSplashScreen />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : userType ? (
        <Layout>
          <BasePage />
          {isAuthorizedByAPI && UnauthorizedSignoutPopup()}
        </Layout>
      ) : (
        <UserTypeSelectionPage />
      )}
    </Switch>
  );
}
