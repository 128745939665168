import React, { useState, useEffect } from 'react'
import { useForm, } from 'react-hook-form'
import { Header } from './header/header';
import { handleFetchPost } from '../../../utils/apiCallsProvider';
import { DataConstant, UrlConstant } from '../../constants';
import _ from 'lodash';
import { LayoutSplashScreen } from '../../../_metronic/layout';
import { useIntl } from "react-intl";

export const TrainingResultPage = ({ history, location,
    match: {
        params: {
            userTrainingSubjectProgressId, detailName, subjectId
        }
    }
}) => {
    const { state: { answerDetail } } = location;

    const [isApiCalled, setIsApiCalled] = useState(false);
    const intl = useIntl();

    const methods = useForm();

    const routeToTestPage = () => {
        resetUserTrainingQuestionAnswerProgress("test");
    }

    const routeToSubjectList = () => {
        resetUserTrainingQuestionAnswerProgress("testList");
    }

    const [displayList, setDisplayList] = useState([]);

    const resetUserTrainingQuestionAnswerProgress = (directionName) => {

        let requestParam = {
            userTrainingSubjectTestAndTopicProgressId: answerDetail?.userTestResult?.userTrainingSubjectTestAndTopicProgressId,
        };
        handleFetchPost(
            UrlConstant.clientTraining.resetUserTrainingQuestionAnswerProgress,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase()
            ) {

                if (_.toLower(directionName) === _.toLower("test")) {
                    history.push({
                        pathname: `/clientTraining/trainingQuestionPage/${userTrainingSubjectProgressId}/${encodeURI(detailName)}/${answerDetail?.userTestResult?.userTrainingSubjectTestAndTopicProgressId}`,
                        state: { dataDetails: answerDetail?.userTestResult },
                    });

                }
                else {
                    history.push({
                        pathname: `/clienttrainning/user/topicandTest/${userTrainingSubjectProgressId}/${encodeURI(detailName)}/${answerDetail?.userTestResult?.masterSubjectId}`,
                        state: { dataDetails: answerDetail?.userTestResult },
                    });

                }

            }
        });
    };


    const getQuestionList = () => {
        let newuserQuestionResultList = _.map(answerDetail?.userQuestionResultList, (i) => {
            let newObj = i;
            newObj.testQuestionAnswerList = _.filter(answerDetail?.userQuestionAnswerStatusList, { testQuestionId: i?.testQuestionId }) || [];
            return newObj;
        });
        setDisplayList(newuserQuestionResultList);
    };

    const handleContinue = () => {
        history.push({
            pathname: `/clienttrainning/user/topicandTest/${userTrainingSubjectProgressId}/${encodeURI(detailName)}/${answerDetail?.userTestResult?.masterSubjectId}`,
            state: { dataDetails: answerDetail?.userTestResult },
        });
    }



    useEffect(() => {
        getQuestionList()
    }, [])

    const TrainingResult = () => {

        return (
            <>
                <div className='px-5 my-3'>
                    {(_.toLower(answerDetail?.userTestResult?.testResult) === _.toLower("Pass")) ?
                        <span className="font-weight-bolder text-white font-size-h6 mt-2 px-5 py-2 rounded-pill cm-bg-success">
                            {`You passed - ${answerDetail?.userTestResult?.resultPercentage}%`}
                        </span>
                        :
                        <span className="font-weight-bolder text-white font-size-h6 mt-2 px-5 py-2 rounded-pill cm-bg-error">
                            {`You failed - ${answerDetail?.userTestResult?.resultPercentage}%`}
                        </span>
                    }
                </div>

                <div class="cm-result-page">
                    <LayoutSplashScreen visible={isApiCalled} />

                    {_.map(_.orderBy(displayList, "userTrainingTestQuestionAnswerProgressId"), (item, index) => {
                        return (
                            <div key={index}>
                                <div>
                                    <br />
                                    <span className="font-weight-bolder d-flex text-dark font-size-h3 mb-0 d-block ">
                                        <span>{index + 1 + "."}</span><p>&nbsp;</p>
                                        <div dangerouslySetInnerHTML={{ __html: (item?.question) }}>
                                        </div>
                                    </span>

                                    <div className="col-xl-6">
                                        <div >
                                            {item?.testQuestionAnswerList?.map((option, index) => {
                                                return (
                                                    <>
                                                        <div class={`custom-control custom-${item?.testQuestionAnswerList?.length > 1 ? "checkbox" : "radio"}`}>
                                                            <input
                                                                type={item?.testQuestionAnswerList?.length > 1 ? "checkbox" : "radio"}
                                                                name={option?.testQuestionAnswerId}
                                                                value={option?.testQuestionAnswerId}
                                                                className=" custom-control-input"
                                                                checked={true}
                                                                id={option?.testQuestionAnswerId} />
                                                            <label className={`font-weight-normal text-dark font-size-h5 custom-control-label ${_.toLower(option.answerStatus) === _.toLower(DataConstant.trainingResult[0].label) ? "success" : "error"}`} for={option.questionOption}>{option.questionOption}</label>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    }
                    )}


                </div>
            </>
        )

    };

    return (
        <div>
            <form>
                <Header history={history} type="resultPage" redirectionCall={routeToTestPage} />
                <div>
                    <div className="font-weight-bolder text-dark font-size-h2 px-5">
                        {detailName}
                    </div>
                    <div>
                        <TrainingResult />
                    </div>
                    {(_.toLower(answerDetail?.userTestResult?.testResult) !== _.toLower("Pass")) ?
                        <div className='row p-5 fixed-bottom'>
                            <div className='col'>
                                <button type="button" class="btn btn-outline-primary btn-block" onClick={() => routeToSubjectList()}>{intl.formatMessage({ id: "client.training.test.result.title1" })}</button>
                            </div>
                            <div className='col'>
                                <button type="button" class="btn btn-primary btn-block " onClick={() => routeToTestPage()}>{intl.formatMessage({ id: "client.training.test.result.title2" })}</button>
                            </div>
                        </div>
                        :
                        <div className='row p-5 fixed-bottom'>
                            <div className='col'>
                                <button type="button" class="btn btn-primary btn-block " onClick={() => handleContinue()}>{intl.formatMessage({ id: "client.training.test.result.title3" })}</button>
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}
