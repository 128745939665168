import React, { useEffect, useState } from 'react'
import { Header } from './header/header'
import { handleFetchPost } from '../../../utils/apiCallsProvider'
import { DataConstant, UrlConstant } from '../../constants'
import { LayoutSplashScreen } from "../../../_metronic/layout";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { convertPhraseToURLCompatible } from '../../../utils/helper';

export const TrainingSubjectList = ({ location, history, match: {
    params: { userDetailId, type }

} }) => {
    const handlePage = (e) => {
        history.push({
            pathname: `/clienttrainning/user/${e?.type}/${e?.userTrainingSubjectProgressId}/${convertPhraseToURLCompatible(e?.name)}/${e?.masterSubjectId}`,
            state: { dataDetails: e },
        });
    }

    const { state: { dataDetails } } = location;

    const [displayList, setDisplayList] = useState([]);
    const [isTrainingCompleted, setIsTrainingCompleted] = useState(false);
    const [isApiCalled, setIsApiCalled] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const intl = useIntl();

    const clientTrainingResult = DataConstant.clientTrainingResult.progressStatus;

    const getSubjectList = () => {

        let requestParam = {
            userDetailId: dataDetails
        };

        handleFetchPost(
            UrlConstant.clientTraining.getUserTrainingSubjectList,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {
                if (response?.data?.length === 0) { setIsTrainingCompleted(true) }
                else {
                    let newData = _.map(response?.data, (item) => {
                        return {
                            ...item,
                            userDetailId: dataDetails,
                            type: "topicandTest",
                        }
                    })
                    setDisplayList({ data: newData });
                    if (_.countBy(newData, { progressStatus: clientTrainingResult[0].value })?.true === newData?.length) {
                        setIsTrainingCompleted(true)
                    }
                }
            }
            setIsApiCalled(false);
        });
    };

    const redirectResponseToMobileApp = () => {
        const responseObject = {
            isTrainingCompleted: isTrainingCompleted
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(responseObject));
    }

    useEffect(() => {
        getSubjectList();
    }, [])

    useEffect(() => {
        setIsApiCalled(true);
        getSubjectList(userDetailId);
    }, [type, dataDetails]);

    return (
        <div>
            <LayoutSplashScreen visible={isApiCalled} />
            <Header history={history}
                displayList={displayList} setFilteredData={setFilteredData}
                type="TestSelection" />
            <div className="p-5">
                <div className="mb-4">
                    <span className="font-weight-bolder text-dark font-size-h2 ">
                        <FormattedMessage id="aside.menu.learningCenter" />
                    </span>
                </div>
                {filteredData.length > 0 ? (_.map(filteredData, (item) => {
                    return (
                        <>
                            <div className="card border-dark mb-3">
                                <div className="card-body p-5">
                                    <div className="card-title font-size-h3">{item?.name}
                                        <div className="card-text font-size-h6">{item?.description}</div>
                                        <hr />
                                    </div>
                                    {clientTrainingResult && clientTrainingResult?.map((items) => {
                                        return (
                                            <>
                                                <div className={`row align-items-center justify-content-${item?.hasAnyTest ? 'between' : 'end'} pl-5 pr-5`}>
                                                    {item?.hasAnyTest && (<div>{_.toLower(item?.progressStatus) == _.toLower(items?.value) ?
                                                        <p className="card-text font-weight-bold text-success font-size-h6">{intl.formatMessage({ id: "client.training.test.selection.title2" })}
                                                            : {item?.progressStatus}</p> : <p class="card-text font-weight-bold text-warning font-size-h6">{intl.formatMessage({ id: "client.training.test.selection.title2" })} : {item?.progressStatus}</p>}
                                                    </div>)}
                                                    <div>
                                                        <button type="button" onClick={() => handlePage(item)} className="w-100px btn btn-outline-primary btn-lg font-size-h4 font-weight-bolder text-primary">{intl.formatMessage({ id: "client.training.test.selection.title4" })}</button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                            <br />
                        </>
                    );
                })
                ) : displayList?.data?.length > 0 ? (_.map(displayList.data, (item) => {
                    return (
                        <>
                            <div className="card border-dark mb-3">
                                <div className="card-body p-5">
                                    <div className="card-title font-size-h3">{item?.name}
                                        <div className="card-text font-size-h6">{item?.description}</div>
                                        <hr />
                                    </div>
                                    {clientTrainingResult && clientTrainingResult?.map((items) => {
                                        return (
                                            <>
                                                <div className={`row align-items-center justify-content-${item?.hasAnyTest ? 'between' : 'end'} pl-5 pr-5`}>
                                                    {item?.hasAnyTest && (<div>{_.toLower(item?.progressStatus) == _.toLower(items?.value) ?
                                                        <p className="card-text font-weight-bold text-success font-size-h6">{intl.formatMessage({ id: "client.training.test.selection.title2" })}
                                                            : {item?.progressStatus}</p> : <p class="card-text font-weight-bold text-warning font-size-h6">{intl.formatMessage({ id: "client.training.test.selection.title2" })} : {item?.progressStatus}</p>}
                                                    </div>)}
                                                    <div>
                                                        <button type="button" onClick={() => handlePage(item)} className="w-100px btn btn-outline-primary btn-lg font-size-h4 font-weight-bolder text-primary">{intl.formatMessage({ id: "client.training.test.selection.title4" })}</button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                            <br />
                        </>
                    );
                })
                ) : (
                    <div className="text-center mt-48">
                        <h3>
                            <FormattedMessage id="traininguser.trainingNotSetYet" />
                        </h3>
                    </div>
                )}
                {isTrainingCompleted && (<div className='row p-5 fixed-bottom'>
                    <div className='col'>
                        <button type="button" className="btn btn-primary btn-block " onClick={redirectResponseToMobileApp}>{intl.formatMessage({ id: "client.training.test.result.title3" })}</button>
                    </div>
                </div>
                )}
            </div>
        </div >
    );
}

