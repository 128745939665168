export const getMappingFromStateId = () =>{
    const url = new URL(window.location.href);
    const stateIdValue = url.searchParams.get('stateId');
    const stateKeyMappingData = process.env.REACT_APP_STATE_KEY_MAPPING ? JSON.parse(process.env.REACT_APP_STATE_KEY_MAPPING) : null;
    const objectWithStateId = stateKeyMappingData?.PaymentSeparation?.find(obj => obj.StateIds.includes(Number(stateIdValue)));
    return objectWithStateId;
}

export const getApiLoginIdAndClientKey = () => {
  const MappingObj = getMappingFromStateId();
  const url = new URL(window.location.href);
  const stateKeyMappingData = process.env.REACT_APP_STATE_KEY_MAPPING ? JSON.parse(process.env.REACT_APP_STATE_KEY_MAPPING) : null;
  const enablePaymentSeparation = url.searchParams.get('enablePaymentSeparationFlag');
  if ((enablePaymentSeparation !== "false") && MappingObj?.ClientKey) {
    return {
      apiLoginId: MappingObj?.ApiLoginId,
      clientKey: MappingObj?.ClientKey,
    };
  } else if (enablePaymentSeparation !== "false") {
    return {
      apiLoginId: stateKeyMappingData?.PaymentSeparation?.[0]?.ApiLoginId,
      clientKey: stateKeyMappingData?.PaymentSeparation?.[0]?.ClientKey,
    };
  } else {
    return {
      apiLoginId: process.env.REACT_APP_PAYMENT_APILOGINID,
      clientKey: process.env.REACT_APP_PAYMENT_CLIENTKEY,
    };
  }
};
