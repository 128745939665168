import * as authProvider from "./authProvider";
import { LoaderSlice } from "../redux/loader/loaderSlice";
import { actions as authReduxAction } from "../app/modules/Auth/_redux/authRedux";
import store from "../redux/store";
import { signOut } from "../utils/authProvider";

const { actions } = LoaderSlice;

let apiCount = 0;
export const handleFetchGet = async (
    requestURL,
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "get",
            headers: {
                "content-type": "application/json; charset=UTF-8",
            },
        };
        increaseCounter();
        if (authorizationRequired) {
            authProvider.acquireAccessToken().then((silentResponse) => {
                if (silentResponse) {
                    // Getting subdomain from url
                    const { hostname } = window.location;
                    let subDomain = hostname.split(".");
                    if (subDomain && subDomain.length > 0) {
                        subDomain = subDomain[0];
                    }
                    const userDetail = JSON.parse(localStorage.getItem("userDetail"));

                    options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
                    options.headers.WhiteLabel = "hydreight-backoffice-prod";
                    options.headers.UserDetail = userDetail;
                    fetch(requestURL, options)
                        .then(
                            (response) => {
                                return response.json();
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .then(
                            (responseJson) => {
                                if (responseJson.statusCode == 401) {
                                    store.dispatch(authReduxAction.SetIsAuthorizedByAPI({ isAuthorizedByAPI: true }));
                                    return;
                                }
                                resolve(responseJson);
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .catch((err) => {
                            reject(err);
                        })
                        .finally(() => {
                            decreaseCounter();
                        });
                }
                else {
                    signOut();
                }
            });
        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });

export const handleFetchPost = async (
    requestURL,
    requestBody,
    requestHeader = "application/json; charset=UTF-8",
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "post",
            headers: {
                "content-type": "application/json; charset=UTF-8",
            },
            body: requestBody,
        };

        if (requestHeader) {
            options.headers["content-type"] = `${requestHeader}`;
        }
        increaseCounter();
        if (authorizationRequired) {
            authProvider.acquireAccessToken().then((silentResponse) => {
                if (silentResponse) {
                    // Getting subdomain from url
                    const { hostname } = window.location;
                    let subDomain = hostname.split(".");
                    if (subDomain && subDomain.length > 0) {
                        subDomain = subDomain[0];
                    }
                    let responsedata;

                    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
                    options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
                    options.headers.WhiteLabel = "hydreight-backoffice-prod";
                    options.headers.UserDetail = userDetail;
                    fetch(requestURL, options)
                        .then(
                            (response) => {
                                responsedata = response;
                                return response.json();
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .then(
                            (responseJson) => {
                                if (responsedata.status !== 200) {
                                    reject(responseJson);
                                } else {
                                    if (responseJson.statusCode === 401) {
                                        store.dispatch(authReduxAction.SetIsAuthorizedByAPI({ isAuthorizedByAPI: true }));
                                        return;
                                    }
                                    resolve(responseJson);
                                }
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .catch((err) => {
                            reject(err);
                        })
                        .finally(() => {
                            decreaseCounter();
                        });
                } else {
                    signOut();
                }
            });
        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responseJson.statusCode == 401) {
                            signOut();
                            return;
                        }
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });

export const handleFetchFileUploadPost = async (
    requestURL,
    requestBody,
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "post",
            headers: {
                Accept: "application/json",
            },
            body: requestBody,
        };
        increaseCounter();
        if (authorizationRequired) {
            authProvider.acquireAccessToken().then((silentResponse) => {
                if (silentResponse) {
                    // Getting subdomain from url
                    const { hostname } = window.location;
                    let subDomain = hostname.split(".");
                    if (subDomain && subDomain.length > 0) {
                        subDomain = subDomain[0];
                    }
                    let responsedata;
                    const userDetail = JSON.parse(localStorage.getItem("userDetail"));

                    options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
                    options.headers.WhiteLabel = "hydreight-backoffice-prod";
                    options.headers.UserDetail = userDetail;
                    fetch(requestURL, options)
                        .then(
                            (response) => {
                                responsedata = response;
                                return response.json();
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .then(
                            (responseJson) => {
                                if (responseJson.statusCode == 401) {
                                    signOut();
                                    return;
                                }
                                if (responsedata.status !== 200) {
                                    reject(responseJson);
                                } else {
                                    resolve(responseJson);
                                }
                            },
                            (err) => {
                                reject(err);
                            }
                        )
                        .catch((err) => {
                            reject(err);
                        })
                        .finally(() => {
                            decreaseCounter();
                        });
                } else {
                    signOut();
                }
            });
        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });

const increaseCounter = () => {
    if (apiCount === 0) {
        store.dispatch(actions.setLoader({ apiCount: apiCount }));
    }
    apiCount += 1;
};

const decreaseCounter = () => {
    apiCount -= 1;
    if (apiCount === 0) {
        store.dispatch(actions.removeLoader());
    }
};
