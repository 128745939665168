import React, { useEffect, useMemo, useRef } from "react";
import { getApiLoginIdAndClientKey } from "../../../_metronic/_helpers/MiscHelpers";

export default function ThirdPartyLocalPaymentPage() {
    const btnRef = useRef();
    const { apiLoginId, clientKey } = useMemo(() => getApiLoginIdAndClientKey(), []);
  
    useEffect(() => {
        loadScript("https://jstest.authorize.net/v3/AcceptUI.js");
        window.responseHandlerHosted = responseHandlerHosted;
    }, []);

    // Load AcceptJS In Component
    const loadScript = (src) => {
        var tag = document.createElement("script");
        tag.src = src;
        document.body.appendChild(tag);
    };

    const responseHandlerHosted = (data) => {
        if (data && data.messages.resultCode === "Ok") {
            const HostedCardDetails = {
                cardNumber: data.encryptedCardData.cardNumber,
                expDate: data.encryptedCardData.expDate,
                token: data.opaqueData.dataValue,
                firstName: data.customerInformation.firstName,
                lastName: data.customerInformation.lastName,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(HostedCardDetails));
        } else {
            const errorDetails = {
                error: data.messages.message[0]?.code,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(errorDetails));
        }
    };

    useEffect(() => {
        if (btnRef && btnRef.current) {
            setTimeout(() => {
                btnRef.current.click();
            }, 900);
        }
    }, [btnRef]);

    return (
        <form id="paymentForm" method="POST">
            <div className="text-center">
                <button
                    ref={btnRef}
                    hidden={true}
                    type="button"
                    className="AcceptUI btn-sm btn-primary"
                    style={{ position: "absolute", top: "50%" }}
                    data-billingaddressoptions='{"show":true, "required":true}'
                    data-apiloginid={apiLoginId}
                    data-clientkey={clientKey}
                    data-acceptuiformbtntxt="Submit"
                    data-acceptuiformheadertxt="Card Information"
                    data-paymentoptions='{"showCreditCard": true, "showBankAccount": false}'
                    data-responsehandler="responseHandlerHosted"
                >
                    Pay
                </button>
            </div>
        </form>
    );
}
