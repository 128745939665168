import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { useHistory } from "react-router";
import { useSelector, shallowEqual } from "react-redux";

import { UserTypeSelection } from "./userTypeSelectionPopup";
import { ActionLoadingDialog } from "../../components/loader";
import { DataConstant } from "../../../app/constants";

export const SwitchUserType = forwardRef((props, ref) => {
  const [showUserTypeSelection, setShowUserTypeSelection] = useState(false);
  const history = useHistory();

  const { actionLoader, userType } = useSelector(
    (state) => ({
      actionLoader: state.loader.actionLoader,
      userType: state.auth.userType,
    }),
    shallowEqual
  );

  useImperativeHandle(ref, () => ({
    handleUserTypeSelection() {
      setShowUserTypeSelection(true);
    },
  }));

  const handleCloseSelection = () => {
    setShowUserTypeSelection(false);
  };

  const redirectToDashBoard = () => {
    setShowUserTypeSelection(false);
    if (
      userType?.toLowerCase() ===
      DataConstant.userType.InsurancePartner?.toLowerCase()
    ) {
      history.push("/insuranceSalesReport");
    } else {
      if (window.location.pathname === '/dashboard') {
        window.location.reload();
      }
      else
        history.push("/dashboard");
    }
  };

  return (
    <>
      {actionLoader && <ActionLoadingDialog isLoading={actionLoader} />}
      {showUserTypeSelection && (
        <UserTypeSelection
          show={showUserTypeSelection}
          onHide={handleCloseSelection}
          isFromSwitchAccount={true}
          redirectToDashBoard={redirectToDashBoard}
        />
      )}
    </>
  );
});
