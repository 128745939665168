import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../../components/buttonComponent";

const MobileLogViewPopup = ({ hideMobileLogPopup, log }) => {
  return (
    <Modal
      show={true}
      onHide={hideMobileLogPopup}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="cm-modal-header-title"
        >
          Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <div className="row">
              <div className=" col-md-2">
                <label className="font-weight-bold">Mobile OS Type</label>
              </div>
              <div className="col-md-10">
                <span>{log.mobileOSType}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <label className="font-weight-bold">Mobile OS Version</label>
              </div>
              <div className="col-md-10">
                <span>{log.mobileOSVersion}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <label className="font-weight-bold">Api Version</label>
              </div>
              <div className="col-md-10">
                <span>{log.apiVersion}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <label className="font-weight-bold">User</label>
              </div>
              <div className="col-md-10">
                <span>{log.user}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <label className="font-weight-bold">Location</label>
              </div>
              <div className="col-md-10">
                <span>{log.location}</span>
              </div>
            </div>
            {log.timezone && (
              <div className="row">
                <div className="col-md-2">
                  <label className="font-weight-bold">Timezone</label>
                </div>
                <div className="col-md-10">
                  <label>{log.timezone}</label>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-2">
                <label className="font-weight-bold">WhiteLabel</label>
              </div>
              <div className="col-md-10">
                <span>{log.whiteLabel}</span>
              </div>
            </div>
            {log.errorMessage && (
              <div className="row">
                <div className="col-md-2">
                  <label className="font-weight-bold">Error Message</label>
                </div>
                <div className="col-md-10">
                  <label>{log.errorMessage}</label>
                </div>
              </div>
            )}
            {log.stackTrace && (
              <div className="row">
                <div className=" col-md-2">
                  <label className="font-weight-bold">
                    Exception StackTrace
                  </label>
                </div>
                <div className="col-md-10">
                  <span style={{ overflowWrap: "break-word" }}>
                    {log.stackTrace}
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-12 col-xs-12 text-right">
          <ButtonComponent
            type="button"
            labelId="button.cancel"
            className="btn-sm btn-light mr-2 ml-1"
            onClick={hideMobileLogPopup}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default MobileLogViewPopup;
