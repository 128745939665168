import { TenantConfig } from "../app/configurations/tenantConfig";

// Getting subdomain from url
const { hostname } = window.location;
let subDomain = hostname.split(".");
if (subDomain && subDomain.length > 0) {
  subDomain = subDomain[0];
}

// Getting tenant configuration based on subdomain
const tenant = TenantConfig[subDomain === process.env.REACT_APP_WHITELABELNAME || "backoffice-admin"];

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: tenant.clientId,
    authority: tenant.signIn,
    knownAuthorities: [tenant.authority],
    redirectUri: tenant.redirectURL,
    postLogoutRedirectUri: tenant.postLogoutRedirectURL,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid", tenant.loginScope],
};

export const resetPasswordRequest = {
  authority: tenant.resetPassword,
};

export const changePasswordRequest = {
  authority: tenant.changePassword,
};

// /**
//  * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
//  * To learn more about how to work with scopes and resources, see:
//  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
//  */
export const tokenRequest = {
  scopes: [tenant.loginScope], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here scopes for silent token request
export const silentRequest = {
  scopes: [tenant.loginScope],
};

export const passwordReset = tenant.passwordReset;
export const passwordChange = tenant.passwordChange;
