export const Extensions = () => {
    if (!String.prototype.formatGeneric) {
        String.prototype.formatGeneric = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        };
    }
}