import React from "react";
import { LoadingDialog } from "../../_metronic/_partials/controls";

export function ActionLoadingDialog({ isLoading }) {
  return (
    <div className="cm-loader">
      <LoadingDialog isLoading={isLoading} text="Loading ..." />
    </div>
  );
}
