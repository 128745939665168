import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocalStorage } from "../../components/useLocalStorage";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { signOut } from "../../../utils/authProvider";
import { UserTypeSelection } from "./userTypeSelectionPopup";
import { DecryptText } from "../../../utils/helper";
import { ActionLoadingDialog } from "../../components/loader";
import _ from "lodash";

const UserTypeSelectionPage = () => {
  const [showUserTypeSelection, setShowUserTypeSelection] = useState(false);

  const { actionLoader, authToken } = useSelector(
    (state) => ({ actionLoader: state.loader.actionLoader, authToken: state.auth.authToken }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [userDetail] = useLocalStorage("userDetail");

  const handleCloseSelection = () => {
    setShowUserTypeSelection(false);
    signOut();
  };

  const dispatchUserType = () => {
    const user = JSON.parse(DecryptText(userDetail));
    if (user) {
      dispatch({
        type: "[Set UserDetail] Action",
        payload: {
          userDetailId: user.userDetailId,
          userType: user.userTypeName,
          userTypeId: user.userTypeId,
          businessName: user.businessName,
          businessId: user.businessId,
          whiteLabel: user.whiteLabel,
          currency: user.currency,
          sessionId: user.sessionId,
          allowBusinessToAddMembership: user.allowBusinessToAddMembership,
          allowCashPayment: user.allowCashPayment,
          isWithDoctorNetwork: user?.isWithDoctorNetwork,

        },
      });
    }
  };

  useEffect(() => {
    if (userDetail) {
      dispatchUserType();
      return;
    }
    setShowUserTypeSelection(true);
  }, [userDetail]);

  return (
    <>
      {actionLoader && <ActionLoadingDialog isLoading={actionLoader} />}
      {!showUserTypeSelection && _.isEmpty(authToken) && <LayoutSplashScreen />}
      {showUserTypeSelection && (
        <UserTypeSelection
          show={showUserTypeSelection}
          onHide={handleCloseSelection}
        />
      )}
    </>
  );
};

export default UserTypeSelectionPage;
