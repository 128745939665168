import React from "react";

export const SeriLogActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  formatExtraData
) => {
  const { openViewActionCallBack, viewTitle } = formatExtraData;
  return (
    <>
      <a
        title={viewTitle}
        className="btn btn-icon btn-hover-primary btn-sm mx-1"
        onClick={() => openViewActionCallBack(row)}
      >
        <span className="svg-icon svg-icon-md cm-action-icons">
          <i className="fa fa-eye" />
        </span>
      </a>
    </>
  );
};
