import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { handleFetchPost } from "../../../utils/apiCallsProvider";
import { DataConstant, UrlConstant } from "../../constants";
import { sortCaret, headerSortingClasses } from "../../../_metronic/_helpers";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { GridComponent } from "../../components/gridComponent";
import { useCommonContext } from "../../context/commonContext";
import { SerilogLevelFormatter } from "./serilog-column-formatter/serilogLevelFormatter";
import { SeriLogActionsColumnFormatter } from "./serilog-column-formatter/serilogActionsColumnFormatter";
import { SeriLogFilter } from "./serilog-filter/serilogFilter";
import ServerLogViewPopup from "./serverLogViewPopup";
import MobileLogViewPopup from "./mobileLogViewPopup";
import { actionTypes } from "../../../redux/filter/filterReducer";
import { LayoutSplashScreen } from "../../../_metronic/layout";

const SeriLogPage = () => {
  const intl = useIntl();
  const commonContext = useCommonContext();
  const [backOfficeLog, setBackOfficeLog] = useState({});
  const [mobileLog, setMobileLog] = useState({});

  const [seriLogList, setSeriLogList] = useState({
    entities: [],
    totalCount: 0,
  });

  const [mobileLogList, setMobileLogList] = useState({
    entities: [],
    totalCount: 0,
  });

  const dispatch = useDispatch();

  const [seriLogDialog, setSeriLogDialog] = useState(false);
  const [mobileLogDialog, setMobileLogDialog] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);

  const { historyFilter } = useSelector(
    ({ filter }) => ({
      historyFilter: filter,
    }),
    shallowEqual
  );

  //Api BackOffice call
  const getSeriLogList = () => {
    let pagingInfo = JSON.parse(JSON.stringify(commonProps.queryParams));
    pagingInfo.sortField = pagingInfo.sortField || "Id";
    handleFetchPost(
      UrlConstant.serilog.getBackOfficeLogs,
      JSON.stringify({
        pagingInfo,
        collectionName:
          historyFilter.serviceLog === "" ? null : historyFilter.serviceLog,
        fromDate:
          historyFilter.fromDate &&
          moment(historyFilter.fromDate).format(
            DataConstant.dateFormats.mmddyyyyHHmmss
          ),
        toDate:
          historyFilter.toDate &&
          moment(historyFilter.toDate).format(
            DataConstant.dateFormats.mmddyyyyHHmmss
          ),
        level: historyFilter.levels === "" ? null : historyFilter.levels,
      }),
      "application/json; charset=UTF-8",
      false,
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        const { entities, totalCount } = response.data;
        setSeriLogList({
          entities,
          totalCount,
        });
      }
      setIsApiCalled(false);
    });
  };

  //Api MobileLog call
  const getMobileLogUIList = () => {
    let pagingInfo = JSON.parse(JSON.stringify(commonProps.queryParams));
    pagingInfo.sortField = pagingInfo.sortField || "Id";
    handleFetchPost(
      UrlConstant.serilog.getMobileLog,
      JSON.stringify({
        pagingInfo,
        mobileOSType:
          historyFilter.mobileOSType === "" ? null : historyFilter.mobileOSType,
        fromDate:
          historyFilter.fromDate &&
          moment(historyFilter.fromDate).format(
            DataConstant.dateFormats.mmddyyyyHHmmss
          ),
        toDate:
          historyFilter.toDate &&
          moment(historyFilter.toDate).format(
            DataConstant.dateFormats.mmddyyyyHHmmss
          ),
      }),
      "application/json; charset=UTF-8",
      false,
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        const { entities, totalCount } = response.data;
        setMobileLogList({
          entities,
          totalCount,
        });
      }
      setIsApiCalled(false);
    });
  };

  const commonProps = useMemo(() => {
    return {
      queryParams: commonContext.queryParams,
      setQueryParams: commonContext.setQueryParams,
      ids: commonContext.ids,
    };
  }, [commonContext]);

  const showSerilogDialog = (log) => {
    setBackOfficeLog(log);
    setSeriLogDialog(true);
  };

  const showMobilelogDialog = (log) => {
    setMobileLog(log);
    setMobileLogDialog(true);
  };

  const hideSerilogDialog = () => {
    setSeriLogDialog(false);
  };

  const hideMobileLogDialog = () => {
    setMobileLogDialog(false);
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatExtraData: {
        keyField: "Id",
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      style: {
        width: "50px",
        minWidth: "50px",
      },
    },
    {
      dataField: "level",
      text: intl.formatMessage({ id: "serilog.level.label" }),
      formatter: SerilogLevelFormatter,
      formatExtraData: {
        keyField: "Id",
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    {
      dataField: "utcDateTimeStamp",
      text: intl.formatMessage({ id: "serilog.date.label" }),
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {row.utcDateTimeStamp
              ?
              `${moment(row.utcDateTimeStamp + 'Z').format(DataConstant.dateFormats.mmddyyyyHHmmss)}`
              : null}
          </div>
        );
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "100px",
        minWidth: "120px",
      },
    },
    {
      dataField: "renderedMessage",
      text: intl.formatMessage({ id: "serilog.message.label" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "150px",
        Width: "200px",
        maxWidth: "250px",
      },
    },
    {
      dataField: "exceptionMessage",
      text: intl.formatMessage({ id: "serilog.exception.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "View",
      text: intl.formatMessage({ id: "serilog.view.label" }),
      formatter: SeriLogActionsColumnFormatter,
      formatExtraData: {
        keyField: "id",
        openViewActionCallBack: showSerilogDialog,
      },
      style: {
        minWidth: "80px",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
  ];

  const mobileLogColumns = [
    {
      dataField: "count",
      text: "#",
      formatExtraData: {
        keyField: "Id",
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      style: {
        width: "50px",
        minWidth: "50px",
      },
    },
    {
      dataField: "mobileOSType",
      text: intl.formatMessage({ id: "mobileLogUI.mobileOSType.label" }),

      formatExtraData: {
        keyField: "Id",
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    {
      dataField: "mobileOSVersion",
      text: intl.formatMessage({ id: "mobileLogUI.mobileOSVersion.label" }),

      formatExtraData: {
        keyField: "Id",
      },
      classes: "text-center pl-0",
      headerClasses: "text-center",
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    {
      dataField: "apiVersion",
      text: intl.formatMessage({ id: "mobileLogUI.apiVersion.label" }),
      sort: true,
      classes: "text-center pl-0",
      headerClasses: "text-center",
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    {
      dataField: "errorMessage",
      text: intl.formatMessage({ id: "mobileLogUI.errorMessage.label" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "150px",
        Width: "200px",
        maxWidth: "250px",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
    {
      dataField: "stackTrace",
      text: intl.formatMessage({ id: "mobileLogUI.stackTrace.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "user",
      text: intl.formatMessage({ id: "mobileLogUI.user.label" }),
      style: {
        minWidth: "120px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "exceptionDateTimeUtc",
      text: intl.formatMessage({ id: "mobileLogUI.exceptionDateTime.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "location",
      text: intl.formatMessage({ id: "mobileLogUI.location.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "timezone",
      text: intl.formatMessage({ id: "mobileLogUI.timeZone.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "whiteLabel",
      text: intl.formatMessage({ id: "mobileLogUI.whiteLabel.label" }),
      style: {
        minWidth: "150px",
        Width: "150px",
        maxWidth: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "View",
      text: intl.formatMessage({ id: "serilog.view.label" }),
      formatExtraData: {
        keyField: "id",
        openViewActionCallBack: showMobilelogDialog,
      },
      style: {
        minWidth: "80px",
      },
      classes: "text-center",
      headerClasses: "text-center",
      formatter: SeriLogActionsColumnFormatter,
    },
  ];

  useEffect(() => {
    dispatch({
      type: actionTypes.logs,
      payload: {
        logs: DataConstant.serilog.backOffice,
      },
    });
    dispatch({
      type: actionTypes.serviceLog,
      payload: {
        serviceLog: DataConstant.serilog.serviceLogs.grpcServiceLogs,
      },
    });
  }, []);

  useEffect(() => {
    switch (historyFilter.logs) {
      case DataConstant.serilog.backOffice:
        setIsApiCalled(true);
        getSeriLogList();
        break;
      case DataConstant.mobileLogUI.mobileLog:
        setIsApiCalled(true);
        getMobileLogUIList();
        break;
      default:
        break;
    }
  }, [commonProps.queryParams, historyFilter]);

  return (
    <>
      {seriLogDialog && (
        <ServerLogViewPopup
          hideSerilogPopup={hideSerilogDialog}
          log={backOfficeLog}
        />
      )}
      {mobileLogDialog && (
        <MobileLogViewPopup
          hideMobileLogPopup={hideMobileLogDialog}
          log={mobileLog}
        />
      )}
      <Card>
        <CardBody>
          <LayoutSplashScreen visible={isApiCalled} />
          <SeriLogFilter className="mt-4" />
          {historyFilter.logs?.toLowerCase() ===
            DataConstant.serilog.backOffice.toLowerCase() && (
              <GridComponent
                className="cm-table-fixed-grid1 mt-4"
                entities={seriLogList.entities}
                columns={columns}
                totalCount={seriLogList.totalCount}
                keyField="Id"
                hideSelection={true}
              />
            )}
          {historyFilter.logs?.toLowerCase() ===
            DataConstant.mobileLogUI.mobileLog.toLowerCase() && (
              <GridComponent
                className="cm-table-fixed-grid1 mt-4"
                entities={mobileLogList.entities}
                columns={mobileLogColumns}
                totalCount={mobileLogList.totalCount}
                keyField="Id"
                hideSelection={true}
              />
            )}
        </CardBody>
      </Card>
    </>
  );
};

export default SeriLogPage;
