import _ from "lodash"
import React, { useEffect, useState } from 'react'
import { handleFetchPost } from '../../../utils/apiCallsProvider'
import { DataConstant, UrlConstant } from '../../constants'
import { Header } from './header/header'
import { LayoutSplashScreen } from "../../../_metronic/layout"
import { convertPhraseToURLCompatible, convertURLToPhraseCompatible } from "../../../utils/helper"

const TariningListPage = ({ location, list, history, match }) => {

    const [displayList, setDisplayList] = useState([]);
    const [isApiCalled, setIsApiCalled] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const { state } = location;

    const dataDetails = state?.dataDetails;
    var { params: { userTrainingSubjectProgressId, detailId, type, detailName } } = match;

    if (!detailName) detailName = dataDetails?.subjectName;
    else detailName = convertURLToPhraseCompatible(detailName);

    const handlePage = (e) => {
        setFilteredData([]); //to clear filter data

        if (_.toLower(e?.type) === _.toLower("topicandTest")) {
            history.push({
                pathname: `/clienttrainning/user/${e?.type}/${e?.userTrainingSubjectProgressId}/${convertPhraseToURLCompatible(e?.name)}/${e?.subjectId}`,
                state: { dataDetails: e },
            });
        }
        else if (_.toLower(e?.type) === _.toLower("test")) {
            history.push({
                pathname: `/clienttrainning/test/${convertPhraseToURLCompatible(e?.name)}/${e?.userTrainingSubjectProgressId}/${e?.subjectTestAndTopicId}`,
                state: { dataDetails: { ...e, subjectName: dataDetails?.name } },
            });
        }
        else if (_.toLower(e?.type) === _.toLower("topic")) {
            history.push({
                pathname: `/clienttrainning/user/${e?.type}/${e?.userTrainingSubjectProgressId}/${convertPhraseToURLCompatible(e?.name)}/${e?.subjectTestAndTopicId}`,
                state: { dataDetails: e },
            });
        }
        else if (_.toLower(e?.type) === _.toLower("stepsInfo")) {
            history.push({
                pathname: `/clienttrainning/stepsInfo/${e?.topicStepId}`,
                state: { stepDetail: e },
            });
        }
    }

    const getTopicandTestList = (masterSubjectId) => {

        let requestParam = {
            userTrainingSubjectProgressId: userTrainingSubjectProgressId,
            masterSubjectId: masterSubjectId,
            userDetailId: dataDetails.userDetailId,
        };

        handleFetchPost(
            UrlConstant.clientTraining.getUserTrainingSubjectTestAndTopicList,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {
                //Note: for testing purpose.
                let newData = _.map(response?.data?.userTrainingSubjectTestAndTopicList, (item) => {
                    return {
                        ...item,
                        subjectId: dataDetails.subjectId,
                        userDetailId: dataDetails.userDetailId,
                        type: item.typeName,
                    }
                })
                setDisplayList({ data: newData });
            }
            setIsApiCalled(false);
        });
    };

    const getStepList = (subjectTestAndTopicId) => {

        let requestParam = {
            subjectTestAndTopicId: dataDetails.subjectTestAndTopicId,
            userDetailId: dataDetails.userDetailId,
        };
        handleFetchPost(
            UrlConstant.clientTraining.getUserTrainingTopicDetailStepList,
            JSON.stringify(requestParam),
            "application/json; charset=UTF-8",
            false
        ).then((response) => {
            if (
                response &&
                response.status?.toLowerCase() ===
                DataConstant.apiResponseStatus.success.toLowerCase() &&
                response.data
            ) {
                //Note: for testing purpose.
                let newHeader = "Steps";
                let newData = _.map(response?.data?.trainingTopicStepList, (item) => {
                    return {
                        ...item,
                        subjectId: dataDetails.subjectId,
                        userDetailId: dataDetails.userDetailId,
                        type: "stepsInfo"
                    }
                })
                setDisplayList({ data: newData });
            }
            setIsApiCalled(false);
        });
    };

    useEffect(() => {
        setIsApiCalled(true);
        if (_.toLower(type) === _.toLower("topicandTest")) {
            getTopicandTestList(detailId);
        }
        else if (_.toLower(type) === _.toLower("topic")) {
            getStepList(dataDetails);
        }
        else { setIsApiCalled(false) }
    }, [type, dataDetails]);

    const routeToTestPage = () => {
        history.push({
            pathname: `/clienttrainning/0/${dataDetails?.userDetailId}`,
            state: { dataDetails: dataDetails?.userDetailId },
        });
    }

    return (
        <div class="overflow-hidden">
            <Header history={history} type={type} redirectionCall={routeToTestPage}
                displayListTLP={displayList} setFilteredDataTLP={setFilteredData} />
            <div>
                <br />
                <div className='p-5'>
                    <h3>
                        <b> {detailName} </b>
                    </h3>
                </div>
            </div>
            <div className="cm-training-wrapper">
                {filteredData.length > 0 ? (_.map(filteredData, (item) => {
                    return (
                        <div>
                            <div className="pl-5 pr-5 pt-2 justify-content-between" onClick={() => handlePage(item)}>
                                <text className='font-size-h4'>{item?.name}</text>
                                <div className='float-right'>
                                    {item?.typeName == "Test" ?
                                        item?.progressStatus == "Completed" ?
                                            <text className='font-size-h5 text-success'>{item?.progressStatus}</text> : <text className='font-size-h5 text-warning'>{item?.progressStatus}</text> : null}
                                </div>
                                <hr />
                            </div>
                        </div>
                    );
                })
                ) :
                    displayList?.data?.map((item, index) => {
                        return (
                            <div key={index} >
                                <div className="pl-5 pr-5 pt-2 justify-content-between" onClick={() => handlePage(item)}>
                                    <text className='font-size-h4'>{item?.name}</text>
                                    <div className='float-right'>
                                        {item?.typeName == "Test" ?
                                            item?.progressStatus == "Completed" ?
                                                <text className='font-size-h5 text-success'>{item?.progressStatus}</text> : <text className='font-size-h5 text-warning'>{item?.progressStatus}</text> : null}
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <LayoutSplashScreen visible={isApiCalled} />
        </div >
    )
}
export default TariningListPage