import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { RoutesList } from "./routeList";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {RoutesList.map((route) => (
          <ContentRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}       
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
