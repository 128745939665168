import React from "react";
import { ErrorMessage } from "@hookform/error-message";

export const ErrorComponent = (props) => {
  const { errors, name } = props;

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p className="text-danger pt-1 m-0">{message}</p>
      )}
    />
  );
};
