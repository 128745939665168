import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { DataConstant } from "../constants/dataConstant";

const CommonContext = createContext();

export function useCommonContext() {
  return useContext(CommonContext);
}

export const CommonConsumer = CommonContext.Consumer;

export function CommonContextProvider({ children }) {
  const [queryParams, setQueryParamsBase] = useState(DataConstant.pagingInfo);
  const [ids, setIds] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }     
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    ids,
    setIds,
  };

  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
}
