import DripbarLogo from '../../../../app/assets/images/dripbar.png';
import TelemedicineLogo from '../../../../app/assets/images/telemedicine.png';
import HydreightLogo from '../../../../app/assets/images/hydreight.png';
import MiltestesLogo from '../../../../app/assets/images/miltestes.png';
import MetaVetLogo from '../../../../app/assets/images/metavet.png';
import VSDigitalLogo from '../../../../app/assets/images/vsdigital.png';
import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { Image } from "react-bootstrap";
import { DataConstant } from "../../../../app/constants";
const { REACT_APP_WHITELABELNAME } = process.env;

export function Brand() {
  const uiService = useHtmlClassService();

  let logo = "";
  let logoClass = "";

  switch (REACT_APP_WHITELABELNAME?.toLowerCase()) {

    case DataConstant.whiteLabelNames.telemedicine.name.toLowerCase():
      logo = TelemedicineLogo;
      logoClass = "";

      break;
    case DataConstant.whiteLabelNames.dripbar.name.toLowerCase():
      logo = DripbarLogo;
      logoClass = "p-20";

      break;
    case DataConstant.whiteLabelNames.hydreight.name.toLowerCase():
      logo = HydreightLogo;
      logoClass = "";

      break;
    case DataConstant.whiteLabelNames.milTeste.name.toLowerCase():
      logo = MiltestesLogo;
      logoClass = "p-10";

      break;

    case DataConstant.whiteLabelNames.metaVet.name.toLowerCase():
      logo = MetaVetLogo;
      logoClass = "p-10";

      break;

    case DataConstant.whiteLabelNames.vsdigital.name.toLowerCase():
      logo = VSDigitalLogo;
      logoClass = "mx-auto w-50px";

      break;

    default:
      break;
  }

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        {/* <a className="cm-logo" style={{ color: "white", fontSize: "25px" }}>
          {`${process.env.REACT_APP_WHITELABELNAME}`}
        </a> */}
        <a className={`cm-logo ${logoClass}`} >
          <Image
            src={
              logo
            }
            className="w-100"
            alt="logo"
          />
        </a>

        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
